import { Inject, Injectable } from '@angular/core';
import { Router, NavigationEnd, Event as RouterEvent } from '@angular/router';
import { filter } from 'rxjs/operators';
import { appConfig } from '../../home.config';
import { SHARED_LIBRARY_CONFIG } from 'projects/petszel-shared/src/public-api';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject } from 'rxjs';
import { time } from '@rxweb/reactive-form-validators';

declare let gtag: Function;

declare global {
  interface Window {
    dataLayer: any[];
    gtag: (...args: any[]) => void;
  }
}

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  private environmentType!: string;
  private pageEnterTime: number | null = null;

  constructor(
    @Inject(SHARED_LIBRARY_CONFIG) private config: any,
    private router: Router,
    private logger: NGXLogger
  ) {
    this.initializeGoogleAnalytics(config.gaTrackingId);
    this.environmentType = this.config.environmentType;

    this.router.events
      .pipe(
        filter(
          (event: RouterEvent): event is NavigationEnd =>
            event instanceof NavigationEnd
        )
      )
      .subscribe((event: NavigationEnd) => {
        this.logger.log('Page navigation ended. Previous page:', event.url); // Log the URL of the previous page
        this.pageEnterTime = performance.now(); // Set the new entry time
        this.logger.log('New page entry time set:', this.pageEnterTime); // Log the new entry time
      });
   

        const trafficType =
          this.environmentType === 'local' ? 'internal' : 'external';
        gtag('config', config.gaTrackingId, {
          page_path: this.router.url,
          traffic_type: trafficType,
        });

        if (this.environmentType === 'local') {
          gtag('event', 'internal_traffic', {
            internal_traffic: 'yes',
          });
        }
      }

  private initializeGoogleAnalytics(trackingId: string) {
    const script = document.createElement('script');
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=${trackingId}`;
    document.head.appendChild(script);

    script.onload = () => {
      window.dataLayer = window.dataLayer || [];
      window.gtag = function () {
        window.dataLayer.push(arguments);
      };
      window.gtag('js', new Date());
      window.gtag('config', trackingId);

    };
  }

  trackPageView() {
    const pagePath = this.router.url; // Ensure this is the correct way to get the current URL path in your application
      this.trackEvent('page_view', {
        page_location: window.location.href,
        page_path: pagePath,
        page_title: document.title,
      });
  }

  trackEvent(eventName: string, params: { [key: string]: any }) {
      const userProperties: { [key: string]: any } = {};
      if (eventName === 'page_view') {
        userProperties['pagePath'] = params['page_path'];
      } 

      gtag('event', eventName, {
        ...params,
      });
  }
}