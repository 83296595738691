import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('../app/layout/layout.module').then((m) => m.LayoutModule),
    data: { bodyClass: 'gradient-staff' }
  },
  {
    path: 'environment',
    loadChildren: () =>
      import('../app/health-layout/health-layout.module').then((m) => m.HealthLayoutModule),
    data: { bodyClass: 'gradient-staff' }
  },
  { path: '**', redirectTo: '/' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
