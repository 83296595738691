import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { PetszelSharedModule, SharedLibraryService, sharedLibraryConfigProvider } from 'projects/petszel-shared/src/public-api';
import { appConfig } from '../home.config';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    RouterModule,
    CommonModule,
    PetszelSharedModule,
    LoggerModule.forRoot({
      level: NgxLoggerLevel.DEBUG, // set log level
      serverLogLevel: NgxLoggerLevel.ERROR, // set server log level
      disableConsoleLogging: false, //enable/disable console logging
    }),
  ],
  providers: [
    {
      provide: 'appConfig',
      useValue: appConfig,
    },
    sharedLibraryConfigProvider,
    SharedLibraryService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
