import { Component } from '@angular/core';
import { AnalyticsService } from './services/google-analytics.service';
import { GoogleTagService } from './services/google-tag-manager.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Petszel';
  constructor(private analyticsService: AnalyticsService, private googleTagService: GoogleTagService) {
  }
}
