import { homeEnvironment as devEnvironment, homeEnvironment } from './environments/environment.dev';
import { homeEnvironment as prodEnvironment } from './environments/environment.prod';
import { homeEnvironment as baseEnvironment } from './environments/environment';
import { homeEnvironment as betaEnvironment } from './environments/environment.beta';

export const appConfig = {
  applicationId: 'home',
  petszelUrl: homeEnvironment.production ? 'https://petszel.com' : 'https://dev.petszel.com',
  beta: {
    apiUrl: betaEnvironment.apiUrl,
    beta: betaEnvironment.beta,
  },
  dev: {
    apiUrl: devEnvironment.apiUrl,
    development: devEnvironment.development,
    gaTrackingId: 'G-GJF0ZLJPZJ',
  },
  prod: {
    apiUrl: prodEnvironment.apiUrl,
    production: prodEnvironment.production,
    gaTrackingId: 'G-B61D7YV2G3',
    gtmId: 'GTM-MRXX8Q62'
  },
    apiUrl: baseEnvironment.apiUrl,
    gaTrackingId: 'G-GJF0ZLJPZJ',
};
