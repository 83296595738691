import { Inject, Injectable } from '@angular/core';
import { NavigationEnd, Router, Event as RouterEvent } from '@angular/router';
import { SHARED_LIBRARY_CONFIG } from 'projects/petszel-shared/src/public-api';
import { filter } from 'rxjs';

@Injectable({
    providedIn: 'root',
  })
  export class GoogleTagService {
  
    constructor(
      @Inject(SHARED_LIBRARY_CONFIG) private config: any,
      private router: Router,
    ) {  
      // Initialize Google Tag Manager if in production
      if (this.config.environmentType === 'production') {
        this.initializeGoogleTagManager('GTM-MRXX8Q62'); 
        this.listenToRouterEvents();
    }
    }
  
    private initializeGoogleTagManager(gtmId: string) {
      // GTM script tag
      const gtmScript = document.createElement('script');
      gtmScript.async = true;
      gtmScript.src = `https://www.googletagmanager.com/gtm.js?id=${gtmId}`;
      document.head.appendChild(gtmScript);
  
      // GTM noscript tag for browsers with JavaScript disabled
      const gtmNoScript = document.createElement('noscript');
      const gtmIframe = document.createElement('iframe');
      gtmIframe.src = `https://www.googletagmanager.com/ns.html?id=${gtmId}`;
      gtmIframe.style.display = 'none';
      gtmIframe.style.visibility = 'hidden';
      gtmNoScript.appendChild(gtmIframe);
      document.body.insertBefore(gtmNoScript, document.body.firstChild);
    }
  
    private listenToRouterEvents(): void {
        this.router.events.pipe(
          filter((event: RouterEvent): event is NavigationEnd => event instanceof NavigationEnd)
        ).subscribe((event: NavigationEnd) => {
          const gtmTag = {
            event: 'pageView',
            pagePath: event.urlAfterRedirects,
          };
    
          // Push to dataLayer
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push(gtmTag);
        });
      }
  }
  